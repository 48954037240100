<template>
    <SliderProductos />
</template>

<script>
import SliderProductos from '../components/SliderProductos.vue';


export default {
  name: 'Producto',
  
  components: {
    SliderProductos,
  },

};
</script>
